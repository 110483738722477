$nav-color: #116AD4;
$nav-color-hover: darken($nav-color, 12%);

$nav-toggle-height: 1.25rem;
$nav-toggle-width: 1.75rem;
$nav-toggle-line-translation: 0.35 * $nav-toggle-height;

/* Menu toggle */
#nav-toggle-label {
    color: $nav-color;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;

    height: $nav-toggle-height;
    width: $nav-toggle-width;
    line-height: normal;
    display: block;
    float: left;
    position: relative;

    margin-top: 0.66rem;
    margin-right: 0.66em;
    margin-left: 0.25em;

    border: 0;
    background: transparent;

    cursor: pointer;

    @media only screen and (min-width: 46em) {
        display: none;
    }

    &:before, &:after, #nav-toggle-label-inner:before {
        content: "";
        width: 90%;
        height: 15%;
        background: $nav-color;
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transition: all .5s;
                transition: all .5s;
    }

    &:after {
        -webkit-transform: translate3d(0, $nav-toggle-line-translation, 0);
                transform: translate3d(0, $nav-toggle-line-translation, 0);
    }

    #nav-toggle-label-inner:before {
        -webkit-transform: translate3d(0, 2 * $nav-toggle-line-translation, 0);
                transform: translate3d(0, 2 * $nav-toggle-line-translation, 0);
    }

}

#nav-toggle-checkbox[type=checkbox]:checked {
    + #nav-toggle-label:before {
        -webkit-transform: translate3d(0, $nav-toggle-line-translation, 0) rotate(-225deg);
                transform: translate3d(0, $nav-toggle-line-translation, 0) rotate(-225deg);
    }

    + #nav-toggle-label:after {
        opacity: 0;
    }

    + #nav-toggle-label #nav-toggle-label-inner:before {
        -webkit-transform: translate3d(0, $nav-toggle-line-translation, 0) rotate(45deg);
                transform: translate3d(0, $nav-toggle-line-translation, 0) rotate(45deg);
    }

    + #nav-toggle-label + nav {
        opacity: 1;
        max-height: 15em;
    }

}


nav {
    -webkit-transition: all .2s;
            transition: all .2s;
    opacity: 0;
    max-height: 1px;
    overflow: hidden;
    clear: both;

    @media only screen and (min-width: 46em) {
        max-height: none;
        opacity: 1;
        float: right;
        margin-top: 0;
        clear: none;
    }
}

/* Menu items */
nav ul {
    font-family: "panefresco", sans-serif;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.25rem;
    list-style: none;
    padding: 0;
    margin: 0;

    @media only screen and (min-width: 46em) {
        display: inline-block;
        font-family: "pacifico", sans-serif;
    }

    @media only screen and (min-width: 61em) {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }

    li {
        display: block;
        text-align: left;
        margin-top: 0.3em;

        @media only screen and (min-width: 46em) {
            display: inline-block;
            text-align: center;
            margin: 0 0.3em;
        }
    }

    li > a {
        margin: 0;
        display: block;
        color: $nav-color;
        text-decoration: none;
        position: relative;
        -webkit-transition: all .4s;
                transition: all .4s;

        &:hover {
            color: $nav-color-hover;
        }

        @media only screen and (min-width: 46em) {
            margin: 0 0 0.7em 0;
            padding: 0.7em 0.4em 0 0.4em;
            position: relative;

            &:before {
                -webkit-transition: all .4s;
                        transition: all .4s;
                height: 1em * 0.12;
                width: 76%;
                content: "";
                display: block;
                bottom: -1em * 0.12;
                left: 13%;
                position: absolute;
            }

            &:hover:before, &:active:before {
                -webkit-transition: background 0.4s;
                        transition: all 0.4s;
                background: $nav-color-hover;
            }
        }
    }
}
